import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
// --- external imports ------

import firebase from '~/authentication/lib/firebase';
// --- internal imports ------

import { getTradersUserData } from './apis';
// --- local imports ------

const UserContext = createContext({user: undefined});

function UserProvider(props) {
	const [firebaseUser, setFirebaseUser] = useState(undefined);
	const [tradersUserData, setTradersUserData] = useState(undefined);
	const [isCheckingInitialAuthData, setIsCheckingInitialAuthData] = useState(true);

	const fetchTradersUserData = useCallback(async (user = firebaseUser) => {
		const firebaseToken = await user.getIdToken();
		const newTradersUserData = await getTradersUserData(firebaseToken);
		setTradersUserData(newTradersUserData);
		setIsCheckingInitialAuthData(false);
	}, [firebaseUser]);

	const handleOnAuthStateChanged = useCallback(async (newUser) => {
		if (newUser) {
			setTradersUserData(undefined);
			setFirebaseUser(newUser);
			fetchTradersUserData(newUser);
		}
		else {
			setFirebaseUser(null);
			setTradersUserData(null);
			setIsCheckingInitialAuthData(false);
		}
	}, [fetchTradersUserData]);

	useEffect(() => {
		firebase.onAuthStateChanged(handleOnAuthStateChanged);
	}, [handleOnAuthStateChanged]);
	
	return (
		<UserContext.Provider
			value={{
				firebaseUser,
				tradersUserData,
				isCheckingInitialAuthData,
				setTradersUserData
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
}

export function useUserContext() {
	return useContext(UserContext);
}

export default UserProvider;

export { UserContext };
